<template>
  <v-dialog :value="value" @input="close" max-width="600px" >
    <v-card style="border-radius: 30px !important;">
      <v-row no-gutters align="center">

        <!-- Title + Close icon -->
        <v-col cols="12" class="mt-6">
          <v-row no-gutters>
            <v-col cols="8" class="offset-2">
              <h2 class="font-weight-bold text-center" style="margin:0 auto !important;">{{$t('view.favorites.createDialog.title')}}</h2>
            </v-col>
            <v-col cols="1" class="offset-1">
              <v-btn icon @click="close">
                <v-icon color="primary">fal fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="10" class="offset-md-1 mt-12" v-if="isLoaded">
          <v-form ref="form" lazy-validation>
            <v-row no-gutters justify="center">

              <!-- Type -->
              <v-col cols="12">
                <v-select
                  outlined
                  :items="favoriteTypes"
                  :label="$t('view.favorites.createDialog.form.type')"
                  v-model="form.type"
                />
              </v-col>

              <!-- Label -->
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-if="form.type === 'CUSTOM'"
                  :label="$t('view.favorites.createDialog.form.label')"
                  v-model="form.label"
                />
              </v-col>


              <!-- Address (disabled) -->
              <v-col cols="12">
                <SearchBarFavorites
                  :value="form.address"
                  @change="onSearch" />
              </v-col>

              <AlertBar />

              <v-col cols="12" class="my-6">
                <v-row no-gutters justify="center">
                  <v-btn
                    @click="customSubmit"
                    rounded
                    :disabled="!(!!form.label && !!form.address)"
                    width="150"
                    color="primary"
                    class="font-weight-bold"
                    :loading="isLoadingBtn.save"
                    style="border-radius: 10px !important;font-size: 16px !important;"
                  >
                    {{$t('view.favorites.buttons.add')}}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
           </v-form>

        </v-col>

        <v-col cols="12" v-else>
          <ProgressCircle />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import SearchBarFavorites from "@/components/Common/Controls/SearchBarFavorites";

export default {
  name: "CreateFavoriteDialog",

  components: {
    SearchBarFavorites,
    ProgressCircle: () => import('@/components/Common/ProgressCircle'),
    ButtonBarFormDialog: () => import("@/components/Common/Dialogs/ButtonBarFormDialog"),
    AlertBar: () => import("@/components/Common/AlertBar")
  },

  mixins: [dialogMixin],

  data() {

    return {

      defaultForm: {
        type: "CUSTOM",
        label: "Mon adresse personnalisée",
        address: this.$route.query.q,
        coordinates: this.coordinates
      },

      isLoadingBtn: {
        save: false
      },
      stats: {
        nbWork: 0,
        nbHome: 0
      },
      isLoaded: false
    }
  },

  props: {
    coordinates: {type: Object, default: () => {return {lng: null, lat: null}}}
  },

  mounted() {
    this.getStats()
  },

  methods: {
    getStats() {
      this.$http
      .get(`/map/users/${this.$session.get('id')}/favorites/stats`, {
        headers: {
          Authorization: "Bearer " + this.$session.get('jwt')
        }
      })
      .then(res => {
        this.stats = res.data;
      })
      .catch(err => {
        this.$store.commit("alert/showError", this.$t('common.errors.500'));
      })
      .finally(() => {
        this.isLoaded = true;
      })
    },

    customSubmit() {

      this.isLoadingBtn.save = true;

      this.$http
      .post(`/map/users/${this.$session.get('id')}/favorites`, {...this.form, coordinates: this.coordinates}, {
        headers: {
          Authorization: "Bearer " + this.$session.get('jwt')
        }
      })
      .then(res => {
        this.close(true);
      })
      .catch(err => {
        if (err && err.response && err.response.status === 400 &&
          this.$t('view.favorites.errors').hasOwnProperty(err.response.data.type))
        {
          this.$store.commit("alert/showError", this.$t(`view.favorites.errors.${err.response.data.type}`));
        } else this.$store.commit("alert/showErrorAxios", err);
      })
      .finally(() => {
        this.isLoadingBtn.save = false;
      })
    },

    onSearch(val) {

      this.form.address = val ? val.label : null
      this.form.coordinates = val ? val.coords : null;
    }
  },

  computed: {
     favoriteTypes() {

       let ret = [this.$t('common.favoriteTypes')[2]];

       if (this.stats.nbHome === 0)
         ret.push(this.$t('common.favoriteTypes')[0]);

       if (this.stats.nbWork === 0)
         ret.push(this.$t('common.favoriteTypes')[1]);

       return ret;
     }
  }
}
</script>

<style scoped>

</style>