<template>
  <v-autocomplete
    id="search-bar-landing"
    v-model="model"
    :items="items"
    :search-input.sync="search"
    :loading="isLoading"
    :label="$t('view.favorites.createDialog.form.address')"
    return-object
    append-icon=""
    clearable
    hide-details
    hide-no-data
    hide-selected
    outlined
    item-value="place_id"
    item-text="label"
    :placeholder="$t('view.search.input')"
    @click:clear="clearSearch"
    @change="onSelect"
    no-filter
    @keydown.enter="onSelect(model)"
    clear-icon="fa fa-times"
    background-color="white">
    <template v-slot:no-data>
      <v-list-item v-if="model && !isLoading">
        <v-list-item-title style="font-size: 14px !important;">Rechercher</v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:item="{ item }">
      <template v-if="item.label !== 'LOGO'">
        <v-list-item-content>
          <v-list-item-title v-text="item.label"></v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-else>
        <v-list-item disabled dense style="width: 100% !important;">
          <v-row align="start" class="d-block">
            <v-img  position="right" max-height="15" contain src="@/assets/pictures/powered_by_google_on_white.png" />
          </v-row>
        </v-list-item>
      </template>
    </template>
    <template v-slot:selection="{ item }">
      <span>{{item.label}}</span>
    </template>
  </v-autocomplete>
</template>

<script>

import {v4 as uuidv4} from "uuid";

export default {
  name: "SearchBarFavorites",

  props: {
    value: {type: Object|String, default: () => null},
  },

  mixins: [],

  data() {
    return {
      search: null,
      model: null,
      items: [],
      isLoading: false,
      _timerID: null,
      session_token: uuidv4()
    }
  },

  mounted() {
    if (this.value) {
      this.items = [this.value];
      this.model = this.value;
    }
  },

  methods: {
    clearSearch() {
      this.search = "";
      //this.onSearch();
    },

    onSearch() {
    },

    async onSelect(val) {
      //console.log(val);
      if (val) {

          const place = await this.$http.get(`/map/search/place-details?place_id=${val.place_id}&session_token=${this.session_token}&coords=${this.$store.getters['coords']}`);
          if (place && place.data) {

            //this.model.coords = place.data.coords;
            this.$emit('change', {label: val.label, coords: place.data.coords});
          }
          else {
            this.$store.commit("toast/showError", this.$t('view.search.errors.NO_RESULT'));
          }
      }
    },

    searchAPI(val) {
      console.log(val);
      this.search = val;

      if (!!val) {

        this._timerID = setTimeout(() => {

          if (val === this.search) {
            this.isLoading = true;
            //console.log(val);
            this.$http(`/map/search/autocomplete?q=${val}&session_token=${this.session_token}&coords=${this.$store.getters['coords']}`)
              .then((res) => {
                this.updateItems(res.data.predictions);
              })
              .catch(err => {
                console.log(err);
              })
              .finally(() => {
                this.isLoading = false;
              })
          }
          else
            this.isLoading = false;
        }, 500);
      }
    },

    updateItems(newItems) {
      this.items = [...newItems.map((el) => {
        return  {
          label: el.label,
          place_id: el.place_id,
          types: el.types
        }
      }), {label: 'LOGO'}
      ];
    },

    generateSessionToken() {
      this.session_token = uuidv4();
    }
  },

  computed: {

  },

  watch: {
    value: function (newValue, oldValue) {
      console.log(newValue);
      //this.model = newValue;
      /*if (newValue) {
        this.items = [];
        this.model = newValue;
        //this.items = [newValue];
        //this.generateSessionToken();
        //console.log(newValue.label);
      }*/
    },
    search(newValue) {
      this.searchAPI(newValue);
    }
  }
}
</script>

<style>
.v-text-field--rounded > .v-input__control > .v-input__slot  {
  padding: 0px !important;
}

</style>