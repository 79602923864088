import cloneDeep from 'clone-deep';

export default {

  props: {

    value: {type: Boolean, default: false},
    item: {type: Object, default: null},
    isLoading: {type: Boolean, default: false}
  },

  data() {

    return {
      form: {},
      //isLoading: {btnSave: false}
    }
  },

  created() {

    this.$store.commit("alert/hideAlert");

    if (this.item)
      this.form = cloneDeep(this.item);
    else
      this.form = {...this.defaultForm};
  },

  methods: {

    submit() {
      this.$emit('submit', this.form);
    },

    close(needRefresh = false) {
      this.$store.commit('alert/hideAlert');
      this.form = {...this.defaultForm};
      this.$emit('close', needRefresh);
    }
  },

  watch: {

    value: function(value) {
      this.$store.commit('alert/hideAlert');

      if (value && this.item)
        this.form = cloneDeep(this.item);
      else if (value && !this.item)
        this.form = {...this.defaultForm};
    }
  }
}